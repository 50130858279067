import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "../keyactivation/keyactivation.component.css";
// import CatDog from "../../assets/keyicon.png";
import "font-awesome/css/font-awesome.min.css";
import $ from "jquery";
import axios from "axios";
import { 
  MenuItem,
  Button,
  IconButton,
  Tooltip,
} from "@material-ui/core";
// import Button from '@mui/material/Button';
import { TextField ,Select,FormControl,InputLabel} from "@mui/material";
import Navbar from "../header/header.component.jsx";
import Sidebarcontent from "../sidebar/sidebar.component.jsx";
// import vehiclekey from "../../assets/vehiclekey.png";
// import otherkey from "../../assets/otherkey.png";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as FcIcons from "react-icons/fc";
import Modal from "@material-ui/core/Modal";
import * as MdIcons from "react-icons/md";
import { switchUrls } from "../../api/index.js";
import setCanvasPreview from "../../setcanvasPreview.js"
import Footer from "../footer/footer.component.jsx";
import CloseIcon from "@material-ui/icons/Close";
import { QrReader } from "react-qr-reader";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import Loader from "../../assets/loader.gif";
import imageCompression from "browser-image-compression";
import {Snackbar} from "@mui/material";
import Alert from '@mui/material/Alert';


const baseURL = switchUrls("key");
const basegatewayURL = switchUrls("gateway");
function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) == " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

let usertoken = readCookie("token");
const uid = localStorage.getItem("uid");

// const baseURL = "http://localhost:3050";
class LocationErrorModal extends React.Component {
  render() {
    const { open, handleClose, handleRetry } = this.props;

    return (
      <Modal open={open} onClose={handleClose}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            background: "white",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            maxWidth: "300px", // Adjust the width as needed
            textAlign: "center",
          }}
        >
          <p style={{ marginBottom: "15px" }}>
            Unable to retrieve your location. Please turn on your GPS.
          </p>
          <button
            onClick={handleRetry}
            style={{
              padding: "10px 15px",
              background: "#007BFF",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Retry
          </button>
        </div>
      </Modal>
    );
  }
}

export default class KeyActivation extends React.Component {
  constructor(props) {
    super(props);
    this.imageref = React.createRef();
    this.canvasref = React.createRef();

    this.state = {
      imageUrl: null,
      selectedImage: null,
      tag_number: this.props.match.params.tag_number,
      key_imagelocation: null,
      key_imagename: null,
      imageUrlvector: null,
      tag_status: "active",
      valuable_type: "CarorBikeKeys",
      locationData: {
        latitude: null,
        longitude: null,
      },
      showLocationErrorModal: false,
      valuable_id: null,
      valuableTypeToIdMap: {
        CarorBikeKeys: 1,
        OtherKeys: 2,
      },
      valuableImages: {
        1: "vehiclekey.png",
        2: "otherkey.png",
      },
      imageUrlvector:
        "https://storage.googleapis.com/pettag/qr/assets/vehiclekey.png",
      display_name: this.props.display_name || "", 
      vehicle_brand: "",
      vehicle_otherkeys: "",
      vehicle_licensenumber: "",
      keychain_description: "",
      other_noofkeys: "",
      chasisnumber: "",
      drivinglicense: "",
      snackbaropen: false,
      snackbarmsg: "",
      message: "",
      sidebar: true,
      subMenu: true,
      imagemodal: false,
      crop: "",
      uploaderror: false,
      imageName: "",
      showLoader: false,
      showLocationErrorModal: false,
      modalShown: false,
      retryLocation: false,
      show: false,
      showQR: false,
      tagNotFound: false,
      alreadyActivated: false,
      walletDialogOpen: false,
      snackbarOpen: false,
      snackbarMessage: '',
      snackbarType: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.onImgChange = this.onImgChange.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
    this.cropsetup = this.cropsetup.bind(this);
    this.handelimagecrop = this.handelimagecrop.bind(this);
    this.handelcancel = this.handelcancel.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleAllTags = this.handleAllTags.bind(this);
    this.handleActiveInactive = this.handleActiveInactive.bind(this);
    this.handleLostFound = this.handleLostFound.bind(this);
    this.checkLocation = this.checkLocation.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.activateTag = this.activateTag.bind(this);
    this.handleCloseQRModal = this.handleCloseQRModal.bind(this);
    this.handleQRResult = this.handleQRResult.bind(this);
    this.walletDialogHandleClose = this.walletDialogHandleClose.bind(this);
  }
  handelcancel() {
    this.setState({
      uploaderror: false,
      message: "",
      imagemodal: false,
      crop: "",
      selectedImage: "",
      imageUrl: "",
    });
  }
  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          sessionStorage.setItem("latitude", latitude);
          sessionStorage.setItem("longitude", longitude);
          this.setState({
            locationData: { latitude, longitude },
          });
        },
        (error) => {
          console.error("Error getting location:", error.message);
          this.setState({ showLocationErrorModal: true });
        }
      );
    } else {
      alert("Geolocation is not supported by your browser.");
    }
  };
   checkLocation = ()=>{
    navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
      permissionStatus.onchange = () => {
      
        if (permissionStatus.state === 'granted') {
          this.getLocation();
          // You can trigger a function here to re-fetch location data
        }
        else{
          this.setState({
            showLocationErrorModal: true,
          });
        }
      };
    });
  }
  componentDidMount() {
    $("#profile-image-uploadActi").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    $("#profile-image-uploadActi-1").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     (position) => {
    //       const latitude = position.coords.latitude;
    //       const longitude = position.coords.longitude;
    //       this.setState({ latitude, longitude });
    //     },
    //     (error) => {
    //       console.error("Error getting geolocation:", error.message);
    //       this.setState({ isLocationEnabled: false, showModal: true });
    //     }
    //   );
    // } else {
    //   console.error("Geolocation is not supported by this browser.");
    //   this.setState({ isLocationEnabled: false, showModal: true });
    // }
    this.getLocation();
    this.checkLocation();
  }
  onImgChange(event) {
    this.setState({ imagesArray: "", file: [null] });
    this.fileObj = [];
    this.fileArray = [];
    this.setState({
      imagesArray: [...this.state.imagesArray, ...event.target.files],
    });
    this.fileObj.push(event.target.files);

    for (let i = 0; i < this.fileObj[0].length; i++) {
      this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]));
    }

    this.setState({ file: this.fileArray });
  }
  handleDropdownChange(event) {
    const selectedValuableType = event.target.value;
    const selectedId = this.state.valuableTypeToIdMap[selectedValuableType];
    this.setState({
      valuable_type: selectedValuableType,
      valuable_id: selectedId,
    });
  }
  handleShow = () => {
    this.setState({ show: true });
  };
  handleClose() {
    this.setState({ show: false });
  }

  handleInputChange(e) {
    const inputValue = e.target.value
      .replace(/\s/g, "")
      .toUpperCase()
      .slice(0, 10);
    this.setState({ tag_number: inputValue });
  }
   
   handleNotFound = () => {
    this.setState({ tagNotFound: false });
  };

  handleActivated = () => {
    this.setState({ alreadyActivated: false });
  };
  activateTag() {
  const { tag_number } = this.state;
  const state = {
    tag_number: tag_number.toUpperCase(),
  };
  this.setState({ showLoader: true });
  const loaderTimer = setTimeout(() => {
    this.setState({ showLoader: false });
  }, 5000);

  axios
    .post(`${basegatewayURL}/api/checkTagNumberWithoutHeader`, state, {
      headers: {
        Authorization: this.props.token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      const productcategory = res.data.tag_data.product_category;
      sessionStorage.removeItem("tag_number");

      // Check if product category starts with "Dolphin" or "Smart"
      if (
        productcategory.startsWith("Dolphin") || 
        productcategory.startsWith("Smart")
      ) {
        this.setState({ walletDialogOpen: true });
        return;
      }

      const routes = {
        "QR PET": `/pet-activation/${tag_number}`,
        "QR Key": `/key-activation/${tag_number}`,
        "QR bag": `/bag-activation/${tag_number}`,
        "QR Bag": `/bag-activation/${tag_number}`,
        "E-Bag": `/bag-activation/${tag_number}`,
        "QR Medical": `/bag-activation/${tag_number}`,
        "QR Passport": `/passport-activation/${tag_number}`,
        "E-Passport": `/passport-activation/${tag_number}`,
        "QR Gadget": `/earphone-activation/${tag_number}`,
        "Generic": `/generic-activation/${tag_number}`,
        "QR Wallet": `/wallet-activation/${tag_number}`,
        "QR Human": `/human-activation/${tag_number}`,
        "QR HUMAN": `/human-activation/${tag_number}`,
        "QR Vehicle": `/#/vehicle-activation/${tag_number}`,
      };

      if (routes[productcategory]) {
        window.location.href = routes[productcategory];
      }
    })
    .catch((error) => {
      clearTimeout(loaderTimer);
      this.setState({ showLoader: false });
      const responseData = error.response.data;
      console.log(responseData, "data");
      if (responseData === "Tag not found by the specified number.") {
        // this.setState({ tagNotFound: true });
        this.setState({
            tagNotFound: true,
            snackbarOpen: true,
            snackbarMessage: "Tag not found!",
            snackbarType: "warning",
          });
      } else if (responseData === "Tag is already activated.") {
        // this.setState({ show: false, alreadyActivated: true });
        this.setState({
            alreadyActivated: true,
            snackbarOpen: true,
            snackbarMessage: "Tag is already activated.",
            snackbarType: "info",
          });
      }
    });
}

  handleCloseQRModal() {
    this.setState({ showQR: false });
  }
   walletDialogHandleClose = () => {
    this.setState({ walletDialogOpen: false });
  };
  handleQRResult(result, error) {
    if (result) {
      const activateTag = result?.text
        .split("/")
        [result?.text.split("/").length - 1].toUpperCase();
      this.setState({ activateTag, showQR: false });
    }
    if (error) {
      console.error(error.message);
    }
  }

  renderQRModal() {
    return (
      <Dialog
        open={this.state.showQR}
        fullWidth
        maxWidth="xs"
        onClose={this.handleCloseQRModal}
        aria-labelledby={"Scan QR"}
      >
        <QrReader
          constraints={{
            facingMode: "environment",
            focusMode: "continuous",
            zoom: 4.0,
          }}
          onResult={this.handleQRResult}
          style={{ width: "100%" }}
        />
      </Dialog>
    );
  }

  handleRetry = () => {
    this.setState({ showLocationErrorModal: false });
    this.getLocation();
  };
  // handleChange(event) {
  //   if (event.target.files[0]) {
  //     this.setState({ message: "" });
  //     this.setState({
  //       selectedImage: event.target.files[0],
  //       imageUrl: URL.createObjectURL(event.target.files[0]),
  //     });
  //   }
  // }

  imageSize(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const promise = new Promise((resolve, reject) => {
      reader.onload = function (e) {
        const image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          const { height, width } = this;
          resolve({ height, width });
        };
        image.onerror = reject;
      };
    });

    return promise;
  }
  async handleChange(event) {
    if (event.target.files[0]) {
      var sizeinmb = event.target.files[0].size / (1024 * 1024);
      console.log("image original size:", sizeinmb);
      this.setState({ imagemodal: true });
      this.setState({ message: "" });
      this.setState({
        selectedImage: event.target.files[0],
        imageUrl: URL.createObjectURL(event.target.files[0]),
        isuploading: true,
        imageName: event.target.files[0].name,
      });
    } else {
      console.log("failed");
    }
  }

  deleteimage = (e) => {
    axios
      .post(`${baseURL}/api/key/deletekeyimage`, this.state, {
        headers: { Authorization: usertoken },
      })
      .then((response) => {
        window.location.reload();
        // console.log(response);
        if (response.data === "Key Image removed") {
          this.setState({
            imageUrl:
              "https://storage.googleapis.com/pettag/qr/assets/vehiclekey.png",
            bag_imagelocation: null,
            bag_imagename: null,
          });
        } else if (response.data === "wrong data") {
          this.setState({
            snackbaropen: true,
            snackbarmsg: "Image could not be deleted",
          });
        }
      })
      .catch((error) => {
        this.setState({ snackbaropen: true, snackbarmsg: "Unsuccessful" });
      });
  };
  cropsetup(e) {
    // console.log("dddddddddddd");
    const { width, height } = e.currentTarget;
    const cropminwidth = (150 / width) * 100;
    const cropAr = makeAspectCrop(
      {
        unit: "%",
        width: cropminwidth,
      },
      1,
      width,
      height
    );
    // console.log(cropAr);
    const cropcenter = centerCrop(cropAr, width, height);

    this.setState({ crop: cropcenter });
  }
  handelimagecrop() {
    setCanvasPreview(
      this.imageref?.current,
      this.canvasref?.current,
      convertToPixelCrop(
        this.state.crop,
        this.imageref?.current?.width,
        this.imageref?.current?.height
      )
    );
    // console.log(this.state?.selectedImage);
    this.canvasref?.current?.toBlob(async (blob) => {
      let file = new File([blob], this.state?.selectedImage?.name, {
        type: "image/jpeg",
      });
      const imageDimension = await this.imageSize(file);
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 2,
        maxWidthOrHeight:
          imageDimension?.width > 1300 ? 1300 : imageDimension?.width,
        useWebWorker: true,
      });
      console.log(
        "image size after compression:",
        compressedFile.size / (1024 * 1024)
      );

      this.setState({
        selectedImage: compressedFile,
        imageUrl: URL.createObjectURL(compressedFile),
        key_imagelocation: null,
        isuploading: true,
        imagemodal: false,
        showLoader: false,
      });
    });
  }

  // handleShow() {
  //   window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  // }
  handleLostFound() {
    window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  }

  handleAllTags() {
    window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  }

  handleActiveInactive() {
    window.location.href = `${basegatewayURL}/#/dashboard/${uid}`;
  }
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };
  closeModal = () => {
    this.setState({ showModal: false });
  };
  submitHandler = (e) => {
    e.preventDefault();
    this.setState({ showLoader: true });
    const {
      tag_status,
      valuable_type,
      imageUrl,
      vehicle_licensenumber,
      keychain_description,
      key_imagelocation,
      other_noofkeys,
      chasisnumber,
      drivinglicense,
      vehicle_brand,
      vehicle_otherkeys,
      imageUrlvector,
      valuable_id,
      display_name,
    } = this.state;
    const { latitude, longitude } = this.state.locationData;
    const data = {
      tag_number: this.props.match.params.tag_number,
      tag_status,
      valuable_type,
      imageUrl,
      vehicle_licensenumber,
      keychain_description,
      other_noofkeys,
      chasisnumber,
      drivinglicense,
      vehicle_brand,
      vehicle_otherkeys,
      key_imagelocation,
      imageUrlvector,
      valuable_id,
      latitude,
      longitude,
      display_name,
    };
    axios
      .post(`${baseURL}/api/key/keyactivation`, data, {
        headers: { Authorization: usertoken },
      })
      .then((response) => {
        if (response.data === "Tag Already Activated") {
          this.setState({ snackbaropen: true, snackbarmsg: response.data });
        } else if (response.data === "Tag not Found by the specified Number") {
          this.setState({ snackbaropen: true, snackbarmsg: response.data });
        } else if (response.data === "Key Activated Successfully") {
          if (this.state?.selectedImage) {
            const fd = new FormData();

            fd.append("image", this.state.selectedImage);
            fd.append("imageName", this.state.imageName);
            fd.append("tag_number", this.props.match.params.tag_number);

            axios
              .post(`${baseURL}/api/key/keyimageupload`, fd)
              .then((res) => {
                this.setState({
                  snackbaropen: true,
                  snackbarmsg: "Key Activated Successfully",
                });
                window.location.href =
                  "/dashboard/" + localStorage.getItem("uid");
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                this.setState({ showLoader: false });
              });
          } else {
            window.location.href = "/dashboard/" + localStorage.getItem("uid");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }

  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }

  render() {
    const {
      imageUrl,
      tag_number,
      bag_imagelocation,
      chasisnumber,
      drivinglicense,
      valuable_type,
      vehicle_brand,
      vehicle_licensenumber,
      keychain_description,
      other_noofkeys,
      valuableTypeToIdMap,
      valuableImages,
      locationData,
      showLocationErrorModal,
      sidebar,
      subMenu,
      imagemodal,
      crop,
      uploaderror,
      message,
      display_name,
      show,
      tagNotFound, 
      alreadyActivated,
      walletDialogOpen,
      snackbarOpen,
      snackbarMessage,
      snackbarType,
    } = this.state;
    const { latitude, longitude } = locationData;
    const selectedId = valuableTypeToIdMap[valuable_type];
    const selectedImageFilename = valuableImages[selectedId];
    const imageUrlvectors = `https://storage.googleapis.com/pettag/qr/assets/${selectedImageFilename}`;
    // console.log(bag_imagelocation, "locationiamage");
    // console.log(imageUrl, "purchase date");
    // console.log(imageUrlvectors, "vecto");
    return (
      <>
        <Navbar setName={(name) => this.setState({ display_name: name })}/>
        <div className="bagActivation-main-wrapper">
          <div className="bagActivation-left-wrapper">
            <Sidebarcontent
              handleShow={this.handleShow}
              handleAllTags={this.handleAllTags}
              handleActiveInactive={this.handleActiveInactive}
              handleLostFound={this.handleLostFound}
            />
            <Dialog
              PaperProps={{
                style: { borderRadius: 15 },
              }}
              open={show}
              fullWidth
              maxWidth="xs"
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <h2 className="dashboard-activateTag-popup">Tag Activation</h2>
                <IconButton
                aria-label="close"
                onClick={this.handleClose}
                style={{
                      position: "absolute",
                      top: "0.5em",
                      right: "0.5em",
                      color: "rgba(0, 0, 0, 0.54)", // Adjust as needed
                    }}
              >
            <CloseIcon />
           </IconButton>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.5em",
                  }}
                >
                  <img
                    src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                    style={{ width: "30px", height: "30px" }}
                    alt="QR Code"
                    onClick={() => this.setState({ showQR: true })}
                  />
                  <input
                    className="dashboard-input__field__dialogs"
                    onKeyUp={(e) => {
                      e.target.value = e.target.value
                        .replace(/\s/g, "")
                        .toUpperCase();
                    }}
                    value={this.activatetag}
                    onChange={this.handleInputChange}
                    placeholder="Owner ID"
                    maxLength={10}
                  />
                </div>

                <div
                  style={{
                    padding: "1em",
                    marginBottom: "1px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className="dashboard-verifyOtp-button"
                    onClick={this.activateTag}
                  >
                    ACTIVATE
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
            <Dialog
        PaperProps={{
          style: { borderRadius: 15,padding: '1.5em',position:'relative' },
        }}
        open={walletDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
                aria-label="close"
                onClick={this.walletDialogHandleClose}
                style={{
                    position: 'absolute !impotant' ,
                    top: '10px !impotant',
                    right: '10px !impotant',
                    zIndex: 1,
                  color: '#333', // Adjust close button color
                }}
                className="close-button"
              >
                <CloseIcon />
              </IconButton>
              <DialogContent>
          <DialogContentText id="alert-dialog-description">
         You're activating the Dolphin Smart Tag. Download the Dolphin Tracker App to activate the tag.
          </DialogContentText>
        </DialogContent>
                <DialogActions>
          <Button  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.digitaltag.tag8.tracker",
                      "_blank"
                    )
                  } style={{ color: "blue" }} ><FcIcons.FcAndroidOs size={24} />
                  Android</Button>
          <Button onClick={() =>
                    window.open(
                      "https://apps.apple.com/us/app/dolphin-tracker/id1524423508",
                      "_blank"
                    )
                  } style={{ color: "blue" }}>
             <AiIcons.AiFillApple size={24} />
                  iOS
          </Button>
        </DialogActions> 
      </Dialog>

            <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => this.setState({ snackbarOpen: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          marginTop: "580px", // Optionally adjust margin
          width: "100%",
        }}
      >
        <Alert
          severity={snackbarType}
          onClose={() => this.setState({ snackbarOpen: false })}
          sx={{
            height: "50px",
            fontSize: "1.2rem",
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
          </div>
           
        </div>
      <div
          className={`bag-container ${
            this.state.valuable_type === "OtherKeys" ? "other-keys" : ""
          }`}
        >
          <div className="background-image">
            <div className="bag-activation-container">
              <p>
                <strong>Key Activation</strong>
              </p>
              <div className="bag-activation-form-container">
                <form className="bag-activation-form">
                  <div className="editPetDetails-user-img">
                    {imageUrl?.length ? (
                      <>
                        <img
                          alt="profile"
                          src={imageUrl}
                          className="editPetDetails-image"
                        />
                      </>
                    ) : (
                      <>
                        <img
                          alt="profile"
                          src={imageUrlvectors}
                          className="editPetDetails-image"
                        />
                      </>
                    )}

                    <div className="editPetDetails-edit-btn-wrap">
                      <label
                        htmlFor="upload"
                        className="editPetDetails-edit-button-image"
                        style={{ cursor: "pointer" }}
                      >
                        <MdIcons.MdEdit /> Change
                      </label>

                      <input
                        id="upload"
                        className="editPetDetails-input-user-img"
                        type="file"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div
                      className=" editPetDetails-user-qr mt-3 d-flex align-items-center justify-content-center"
                      style={{ gap: "6px" }}
                    >
                      <img
                        src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                        alt="qr"
                        style={{ width: "30px", height: "30px" }}
                      />
                      <p style={{ marginTop: "15px" }}>
                        <b>
                          {tag_number && tag_number !== "-"
                            ? tag_number
                            : "N/A"}
                        </b>
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="bagactivation-label-container">
                      <FormControl fullWidth>
                              <InputLabel
                                id="demo-simple-select-label"
                              >
                                Valuable Type
                              </InputLabel>
                              <Select
                                labelId="valuable-type-label"
                                id="valuable-type-select"
                                value={valuable_type}
                                label={"valuable Type"}
                                required
                                onChange={this.handleDropdownChange}
                              >
                                <MenuItem value="">Please select</MenuItem>
                        <MenuItem value="CarorBikeKeys">Vehicle Key</MenuItem>
                        <MenuItem value="OtherKeys">Other Key</MenuItem>
                              </Select>
                            </FormControl>
                      </div>
                      <div className="bagactivation-label-container">
                      <Tooltip
                        describeChild
                        title="This name will appear on the scan page when the tag is scanned."
                        leaveTouchDelay={3000}
                        enterTouchDelay={50}
                      >
                        <TextField
                          required
                          type="text"
                          variant="outlined"
                          name="display_name"
                          label="Display Name"
                          value={this.state.display_name}
                          onChange={this.changeHandler}
                          className="bagactivation-flex-item"
                        />
                      </Tooltip>
                      </div>
                   
                      {(valuable_type === "Other Keys" ||
                        valuable_type === "OtherKeys") && (
                        <>
                         <div className="bagactivation-label-container">
                          <TextField
                            label="Keychain Description"
                            className="keydetails-right-form-inputs"
                            name="keychain_description"
                            variant="outlined"
                            value={keychain_description}
                            onChange={this.changeHandler}
                          />
                          
                         </div>
                        <div className="bagactivation-label-container">
                        <TextField
                         label="No of Keys"
                         className="keydetails-right-form-inputs"
                         maxLength="20"
                         variant="outlined"
                         name="other_noofkeys"
                         value={other_noofkeys}
                         onChange={this.changeHandler}
                         />                           
                        </div>         
                        </>
                      )}
                    {(valuable_type === "Car or Bike Keys" ||
                      valuable_type === "CarorBikeKeys") && (
                      <>
                       <div  className="bagactivation-label-container">
                          <TextField
                                label="Keychain Description"
                                className="keydetails-right-form-inputs"
                                name="keychain_description"
                                variant="outlined"
                                value={keychain_description}
                                onChange={this.changeHandler}
                              />
                       </div>
                        <div className="bagactivation-label-container">
                            <TextField
                                label="Vehicle Brand & Model"
                                className="keydetails-right-form-inputs"
                                name="vehicle_brand"
                                variant="outlined"
                                value={vehicle_brand}
                                onChange={this.changeHandler}
                              />
                       </div>
                        <div  className="bagactivation-label-container">
                           <TextField
                                label="No of Keys"
                                className="keydetails-right-form-inputs"
                                maxLength="20"
                                variant="outlined"
                                name="other_noofkeys"
                                value={other_noofkeys}
                                onChange={this.changeHandler}
                              />
                       </div>
                        <div  className="bagactivation-label-container">
                            <TextField
                                label="Vehicle License Number"
                                className="keydetails-right-form-inputs"
                                name="vehicle_licensenumber"
                                variant="outlined"
                                value={vehicle_licensenumber}
                                onChange={this.changeHandler}
                              />
                       </div>         
                        <div className="bagactivation-label-container">
                            <TextField
                                label="Chasis Number"
                                className="keydetails-right-form-inputs"
                                name="chasisnumber"
                                variant="outlined"
                                value={chasisnumber}
                                onChange={this.changeHandler}
                              />
                       </div>
                        <div className="bagactivation-label-container">
                            <TextField
                                label="Driving License Number"
                                className="keydetails-right-form-inputs"
                                name="drivinglicense"
                                variant="outlined"
                                value={drivinglicense}
                                onChange={this.changeHandler}
                              />
                       </div>
                      </>
                    )}
                    {/* <LocationErrorModal
                      open={showLocationErrorModal}
                      handleClose={() =>
                        this.setState({ showLocationErrorModal: false })
                      }
                      handleRetry={this.handleRetry}
                      latitude={latitude}
                      longitude={longitude}
                    /> */}
                    <div className="bagactivation-btn-grp">
                      <Button
                        variant="contained"
                        color="primary"
                        className="submit-login bg-warning text-white mt-2"
                        style={{ width: "100%" }}
                        onClick={this.submitHandler}
                        disabled={!this.state.locationData}
                      >
                        SUBMIT
                      </Button>

                      <Button
                        variant="contained"
                        color="default"
                        className="submit-login bg-white mt-2"
                        style={{ width: "100%" }}
                        onClick={() => {
                          window.location.href =
                            "/dashboard/" + localStorage.getItem("uid");
                        }}
                      >
                        CANCEL
                      </Button>
                    </div>

                    <Snackbar
                      open={this.state.snackbaropen}
                      autoHideDuration={3000}
                      onClose={this.snackbarClose}
                      message={this.state.snackbarmsg}
                      action={[
                        <IconButton
                          key="close"
                          aria-label="Close"
                          color="inherit"
                          onClick={this.snackbarClose}
                        >
                          X
                        </IconButton>,
                      ]}
                    />
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
        <div>
          <Footer />
        </div>


        <Dialog open={imagemodal} fullWidth="sm" maxWidth="sm">
          <DialogTitle>Image</DialogTitle>
          <DialogContent>
            <form>
              <div style={{ margin: "1rem" }}>
                {!uploaderror ? (
                  <>
                    <ReactCrop
                      keepSelection
                      crop={crop}
                      aspect={1}
                      minWidth={100}
                      onChange={(percentCrop) => {
                        this.setState({ crop: percentCrop });
                      }}
                    >
                      <img
                        ref={this.imageref}
                        alt="crop"
                        width="100%"
                        height="100%"
                        src={imageUrl}
                        onLoad={this.cropsetup}
                      ></img>
                    </ReactCrop>
                  </>
                ) : (
                  <>
                    <p style={{ color: "red", textAlign: "center" }}>
                      {message}
                    </p>
                  </>
                )}
              </div>

              <div className="crop-actionsSub">
                <Button onClick={this.handelcancel}>Cancel</Button>
                {!uploaderror ? (
                  <Button
                    onClick={() => {
                      this.setState({ showLoader: true });
                      this.handelimagecrop();
                    }}
                  >
                    Submit
                  </Button>
                ) : null}
              </div>
            </form>
          </DialogContent>
          <canvas ref={this.canvasref} style={{ display: "none" }}></canvas>
        </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={showLocationErrorModal}
        autoHideDuration={6000}
        onClose={() => this.setState({ showLocationErrorModal: false })} 
      >
        <Alert
          style={{ backgroundColor: "#90EE90" }}
          onClose={() => {this.setState({showLocationErrorModal:false})}}
          severity="success"
          sx={{ width: "100%" }}
        >
        To enhance your experience, please enable GPS. This step is optional, and you can still proceed without enabling it.
        </Alert>
      </Snackbar>
        {/* Loader */}
        {this.state.showLoader && (
          <div className="loader-container">
            <div className="loader">
              <img
                src={
                  "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                }
                alt="Loading..."
                loading="lazy"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
          </div>
        )}
        {this.renderQRModal()}
      </>
    );
  }
}
